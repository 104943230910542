import styled from "styled-components";

export const Container = styled.div`
	background: ${({ theme }) => theme.colors.secondaryBackground};
	max-width: 1310px;
	box-shadow: 10px 12px 21.6px rgba(2, 136, 209, 0.77);
	display: flex;
	flex-direction: row;
	gap: 60px;
	border-radius: 15px;
	padding: 3rem 2rem;
	cursor: pointer;

	@media (max-width: 1024px) {
		flex-direction: column;
		gap: 20px;
		padding: 1rem;
	}

	@media (max-width: 768px) {
		padding: 1rem;
	}

	@media (max-width: 480px) {
		padding: 1rem;
	}

	@media (max-width: 375px) {
		box-shadow: 4px 5px 20px rgba(2, 136, 209, 0.77);
		padding: 0.5rem;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const Details = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(3, auto);
	column-gap: 59px;
	row-gap: 10px;

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;

export const Image = styled.img`
	max-width: 353px;
	max-height: 228px;

	@media (max-width: 1024px), (max-width: 768px) {
		max-width: 100%;
		border-radius: 10px;
		height: auto;
	}
`;

export const ExpandedImage = styled.img`
	width: auto;
	height: auto;
	max-height: 300px;
	max-width: 100%;
	margin-bottom: 20px;

	@media (max-width: 1024px), (max-width: 768px), (max-height: 810px) {
		border-radius: 10px;
		max-height: 200px;
		max-width: 90%;
	}
`;

export const Summary = styled.span`
	color: ${({ theme }) => theme.colors.secondaryAccentBlue};
	font-weight: 800;
	font-size: 20px;
	text-align: justify;
	margin-bottom: 20px;

	@media (max-width: 768px) {
		font-size: 18px;
	}

	@media (max-width: 480px), (max-width: 375px) {
		font-size: 14px;
	}

`;

export const Header = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

export const Title = styled.h2`
	color: #8bbaef;
`;

export const ModalContent = styled.div`
	color: ${({ theme }) => theme.colors.secondaryAccentBlue};
	font-weight: 800;
	font-size: 18px;
	text-align: justify;
	margin-bottom: 2rem;
	max-height: 585px;
	width: 100%;

	@media (max-width: 768px) {
		font-size: 16px;
	}

	@media (max-width: 480px) {
		font-size: 14px;
	}

	@media (max-width: 375px) {
		font-size: 12px;
	}
`;

export const Social = styled.svg`
	transition: transform 0.3s ease-in-out;

	&:hover {
		transform: rotate(8deg);
	}
`;

export const FullScreenModal = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.colors.secondaryBackground};
	z-index: 1000;
	overflow-y: auto;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;

	@media (max-width: 768px) {
		padding: 1rem;
	}

	@media (max-width: 480px) {
		padding: 0.5rem;
	}

	@media (max-width: 375px) {
		padding: 0.5rem;
	}
`;

export const CloseButton = styled.button`
	background: ${({ theme }) => theme.colors.secondaryText};
	border-radius: 5px;
	color: white;
	border: none;
	font-size: 32px;
	cursor: pointer;
	margin-bottom: 2rem;
	box-shadow: 0px 0px 20px 4px #00bfa552;

	@media (max-width: 768px) {
		font-size: 28px;
		margin-bottom: 1.5rem;
	}

	@media (max-width: 480px) {
		font-size: 24px;
		margin-bottom: 1rem;
	}

	@media (max-width: 375px) {
		font-size: 20px;
		margin-bottom: 0.5rem;
	}
`;
