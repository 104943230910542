import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Card from "../components/details/Card";
import validateProjects from "../utils/validateProjects";

const ProjectsSection = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-height: calc(100vh - 91px);
	max-width: 100vw;
	overflow-x: hidden;
	padding: 2rem 2rem 0 2rem;

	@media (max-width: 768px) {
		padding: 1rem;
	}

	@media (max-width: 375px) {
		padding: 0.5rem;
	}
`;

const OuterContainer = styled.div`
	width: 75%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
    overflow-x: hidden;

	@media (max-width: 768px) {
		width: 90%;
	}

	@media (max-width: 480px) {
		width: 100%;
	}
`;

const ProjectsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 50px;
	align-items: center;
	padding: 2rem;
	border: 1px solid ${({ theme }) => theme.colors.primaryAccentGreen};
	border-bottom: none;
	border-radius: 15px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	width: 100%;
	flex-grow: 1;

	@media (max-width: 768px) {
		padding: 1rem;
	}

	@media (max-width: 375px) {
		padding: 0.5rem;
	}
`;

const Projects = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const loadProjects = async () => {
            try {
                const context = require.context("../contents/projects", false, /\.json$/);
                const projectsData = context.keys().map(context);

                const validProjects = projectsData.filter(validateProjects);
                setProjects(validProjects);
            } catch (error) {
                console.error("Error loading projects:", error);
            }
        };

        loadProjects();
    }, []);

    return (
        <ProjectsSection>
            <OuterContainer>
                <h2>Here are some of the Projects I have worked in</h2>
                <ProjectsContainer>
                    {projects.map((project, index) => (
                        <Card
                            key={index}
                            summary={project.overview}
                            imageUrl={project.imageUrl}
                            repoUrl={project.repoUrl}
                            details={[
                                { title: "Project Name", content: [project.projectName] },
                                { title: "Technologies", content: project.technologiesUsed }
                            ]}
                        />
                    ))}
                </ProjectsContainer>
            </OuterContainer>
        </ProjectsSection>
    );
};

export default Projects;
