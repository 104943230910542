import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Card from "../components/details/Card";
import validateExperience from "../utils/validateExperience";

const ExperienceSection = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-height: calc(100vh - 91px);
	max-width: 100vw;
	overflow-x: hidden;
	padding: 2rem 2rem 0 2rem;

	@media (max-width: 768px) {
		padding: 1rem;
	}

	@media (max-width: 375px) {
		padding: 0.5rem;
	}
`;

const OuterContainer = styled.div`
	width: 75%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
    overflow-x: hidden;

	@media (max-width: 768px) {
		width: 90%;
	}

	@media (max-width: 480px) {
		width: 100%;
	}
`;

const ExperiencesContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 50px;
	align-items: center;
	padding: 2rem;
	border: 1px solid ${({ theme }) => theme.colors.primaryAccentGreen};
	border-bottom: none;
	border-radius: 15px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	width: 100%;
	flex-grow: 1;

	@media (max-width: 768px) {
		padding: 1rem;
	}

	@media (max-width: 375px) {
		padding: 0.5rem;
	}
`;

const Experience = () => {
    const [experiences, setExperiences] = useState([]);

    useEffect(() => {
        const loadExperiences = async () => {
            try {
                const context = require.context("../contents/experiences", false, /\.json$/);
                const experienceData = context.keys().map(context);

                const validExperiences = experienceData.filter(validateExperience);
                setExperiences(validExperiences);
            } catch (error) {
                console.error("Error loading experiences:", error);
            }
        };

        loadExperiences();
    }, []);

    return (
        <ExperienceSection>
            <OuterContainer>
                <h2>My Experiences</h2>
                <ExperiencesContainer>
                    {experiences.length > 0 ? (
                        experiences.map((experience, index) => (
                            <Card
                                key={index}
                                summary={experience.summary || "No summary available"}
                                imageUrl={experience.imageUrl || ""}
                                repoUrl=""
                                details={[
                                    { title: "Company", content: [experience.companyName || "Unknown"] },
                                    { title: "Duration", content: [`${experience.startDate || "Unknown"} - ${experience.endDate || "Present"}`] },
                                    { title: "Technologies", content: experience.technologiesUsed || ["N/A"] },
                                    { title: "Title", content: [experience.jobTitle || "N/A"] }
                                ]}
                            />
                        ))
                    ) : (
                        <p>No experiences available.</p>
                    )}
                </ExperiencesContainer>
            </OuterContainer>
        </ExperienceSection>
    );
};

export default Experience;
